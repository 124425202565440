<template>
  <base-layout>
    <ion-content class="has-header has-subheader has-bottom-banner ">
      <h4 class="item mx-3" v-html="abstract.title"
          :style="{'font-size' : fontsize + 'rem','line-height' : fontsize *1.4 + 'rem'}"></h4>

      <div class="mx-3" v-html="abstract.text" :style="{'font-size' : fontsize + 'rem','line-height' : fontsize *1.4 + 'rem'}"></div>
    </ion-content>
    <div class="mt-3" style="display: flex; justify-content: center">
      <div class="btn-group" role="group" aria-label="Basic radio toggle button group" style="width: 100%;">
        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
               @click="changeFontSize(0.8)" :checked="fontsize == 0.8">
        <label class="py-2 change-size-btn  btn btn-outline-primary" for="btnradio1"  style="font-size: 0.9rem">Aa</label>

        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
               @click="changeFontSize(1)" :checked="fontsize == 1">
        <label class="py-2 change-size-btn btn btn-outline-primary" for="btnradio2"  style="font-size: 1.1rem">Aa</label>

        <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off"
               @click="changeFontSize(1.2)" :checked="fontsize == 1.2">
        <label class="py-2 change-size-btn btn btn-outline-primary" for="btnradio3" style="font-size: 1.4rem">Aa</label>
      </div>
    </div>

  </base-layout>
</template>

<script>
import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import moment from "moment";

export default defineComponent({
  name: "AbstractDetail",
  data() {
    return {
      abstract: {},
      fontsize: 1
    };
  },
  components: {
    IonContent,
  },
  methods: {
    ...mapActions("abstracts", [
      "getAbstract",
    ]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    changeFontSize(size) {
      this.fontsize = size;
    }
  },

  async created() {
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Abstract Detail') {
          this.moment = moment;
          this.abstract = await this.getAbstract(this.$route.params.id);
          this.trackWithLabel(this.abstract.title)

        }
      }
    }
  }
});
</script>
<style scoped>
#subpage-detail {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;
}

.change-size-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
